import React from 'react';

import { PAGES } from '../../utils/utils';
import Contact from '../../components/en/contact';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const ContactPage = () => (
  <Layout lang="en" page={PAGES.CONTACT}>
    <SEO title="Contact" lang="en" />
    <Contact />
  </Layout>
);

export default ContactPage;
