import React from 'react';

import { PAGES, PAGES_URL } from '../../utils/utils';

const Contact = () => {
  function popPrecisionsSujet(sujet) {
    const display = sujet === 'Other' ? 'inline-block' : 'none';
    document.getElementById('precisionsSujet').style.display = display;
    //reset field if sujet !== 'autre'
    // document.getElementById(
    //   'precisionsSujet',
    // ).innerHTML = document.getElementById('precisionsSujet').innerHTML;
  }

  function popPrecisionsConnu(connu) {
    const display = [
      'Search Engine',
      'Website',
      'Medias',
      'Social Networks',
    ].includes(connu)
      ? 'inline-block'
      : 'none';
    document.getElementById('precisionsConnu').style.display = display;
    //reset field if includes condition is false
    // document.getElementById(
    //   'precisionsConnu',
    // ).innerHTML = document.getElementById('precisionsConnu').innerHTML;
  }

  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Contact</h1>
      </div>

      <div id="img-wrap-contact">
        <iframe
          width="759"
          height="365"
          src="https://maps.google.ca/maps?q=lemire+lemire+avocats+joliette&amp;hl=fr&amp;ie=UTF8&amp;sll=45.751055,-73.61063&amp;sspn=0.034976,0.065746&amp;hq=lemire+lemire+avocats&amp;hnear=Joliette,+Qu%C3%A9bec&amp;cid=11202491731402244976&amp;t=m&amp;ll=45.751055,-73.603249&amp;spn=0.017218,0.038581&amp;output=embed&iwloc=near"
        ></iframe>
      </div>

      <div id="info">
        <div id="texteContact">
          <div itemScope itemType="https://schema.org/LocalBusiness">
            <h2>
              Please contact us by phone, fax, e-mail or through our form below.
            </h2>
            <ul className="colonne1">
              <p itemProp="name" id="contactAlign">
                Lemire Lemire avocats LLP
              </p>
              <li>
                <span itemProp="address">
                  <a
                    href="https://maps.google.ca/maps?q=lemire+lemire+avocats+joliette&hl=fr&ie=UTF8&ll=45.751594,-73.603249&spn=0.017218,0.038581&sll=45.751055,-73.61063&sspn=0.034976,0.065746&hq=lemire+lemire+avocats&hnear=Joliette,+Qu%C3%A9bec&cid=11202491731402244976&t=m&z=15&iwloc=A"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    2724, Chemin Sainte-Marie
                    <br />
                    Mascouche (Québec) J7K 1N1
                  </a>
                </span>
              </li>
              <br />
            </ul>

            <ul className="colonne2">
              <li>
                Phone:{' '}
                <span itemProp="telephone">
                  <a href="tel:+14509661055">450.966.1055</a>
                </span>
              </li>
              <li>
                Fax: <span itemProp="faxNumber">450.966.0193</span>
              </li>
              <li>
                E-Mail:{' '}
                <span itemProp="email">
                  <a href="mailto:info@lemireavocat.com">
                    info@lemireavocat.com
                  </a>
                </span>
              </li>
            </ul>
            <br />
          </div>
        </div>

        <div id="form_container">
          <form
            id="contactform"
            method="POST"
            name="Contact EN"
            data-netlify="true"
            action={PAGES_URL.en[PAGES.MERCI]}
          >
            <input type="hidden" name="form-name" value="Contact EN" />
            <fieldset className="effect8">
              <h1>Contact Form</h1>
              <label className="labLeft" htmlFor="firstname">
                First Name
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="First Name"
                  required
                />
              </label>
              <label className="labRight" htmlFor="lastname">
                Last Name
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Last Name"
                  required
                />
              </label>
              <label className="labLeft" htmlFor="courriel">
                E-Mail
                <input
                  id="courriel"
                  name="courriel"
                  type="email"
                  placeholder="E-Mail"
                  required
                />
              </label>
              <label className="labRight" htmlFor="telnum">
                Phone Number
                <input
                  id="telnum"
                  name="telnum"
                  type="tel"
                  placeholder="Phone Number"
                />
              </label>
              <label htmlFor="sujet">
                Subject
                <select
                  className="customSelect"
                  id="sujet"
                  name="sujet"
                  onChange={(event) => popPrecisionsSujet(event.target.value)}
                  defaultValue=""
                  required
                >
                  <option value="">---</option>
                  <optgroup label="Legal">
                    <option value="Separation-Divorce">
                      Separation-Divorce
                    </option>
                    <option value="Mediation">Mediation</option>
                    <option value="Child Custody - Support">
                      Child Custody - Support
                    </option>
                    <option value="Civil Liability">Civil Liability</option>
                    <option value="Construction">Construction</option>
                    <option value="Insurance">Insurance</option>
                    <option value="Real Estate">Real Estate</option>
                  </optgroup>
                  <optgroup label="Other">
                    <option value="Comments">Comments</option>
                    <option value="Media">Media</option>
                    <option value="Other">Other</option>
                  </optgroup>
                </select>
              </label>
              <label
                id="precisionsSujet"
                className="precisionsSujet"
                htmlFor="precisionsSujet"
              >
                Please specify:{' '}
                <input
                  id="precisionsSujet"
                  name="precisionsSujet"
                  type="text"
                  placeholder="Please specify"
                />
              </label>

              <label htmlFor="description">
                Message
                <textarea
                  id="description"
                  name="description"
                  placeholder="Message"
                  required
                />
              </label>
              <label htmlFor="connu">
                How did you hear about Lemire Lemire avocats LLP?
                <select
                  className="customSelect"
                  id="connu"
                  name="connu"
                  onChange={(event) => popPrecisionsConnu(event.target.value)}
                  defaultValue=""
                >
                  <option value="" disabled>
                    ---
                  </option>
                  <option value="Friends">Friends</option>
                  <option value="Search Engine">Search Engine</option>
                  <option value="Social Networks">Social Networks</option>
                  <option value="Website">Website</option>
                  <option value="Medias">Medias</option>
                </select>
              </label>

              <label
                id="precisionsConnu"
                className="precisionsConnu"
                htmlFor="precisionsConnu"
              >
                Please specify:{' '}
                <input
                  id="precisionsConnu"
                  name="precisionsConnu"
                  type="text"
                  placeholder="Please specify"
                />
              </label>

              <label className="recevoirCopie" htmlFor="recevoirCopie">
                <input
                  id="recevoirCopie"
                  name="recevoirCopie"
                  type="checkbox"
                />
                <span className="recevoirCopieTexte">
                  Send me a copy by e-mail.
                </span>
              </label>
              <input type="hidden" name="form_id" value="603721" />
              <input
                id="saveForm"
                className="button_text"
                type="submit"
                name="submit"
                value="Send"
              />
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
};

Contact.propTypes = {};

export default Contact;
